<template>
<!-- 联系我们 -->
  <div>
<div class="contact">
      <div class="contact_top">
        <div class="contact_line"></div>
        <p class="contact_p">联系我们</p>
      </div>
      <p class="obtain">取得联系</p>
      <div class="information">
        <div class="information_left">
          <div>
            <input
              class="name"
              v-model="name"
              type="text"
              placeholder="请填写您的姓名"
            />
            <input
              class="contact_Telephone"
              v-model="telephone"
              type="number"
              placeholder="请填写您的电话"
            />
          </div>
          <input
            class="my_title"
            v-model="title"
            type="text"
            placeholder="请输入您的标题"
          />
          <textarea
            class="commentt"
            v-model="commentt"
            type="text"
            placeholder="请输入您的评论"
          />
          <div class="Submit" @click="Submit">提交信息</div>
        </div>
        <div class="information_right">
          <p class="English">
            Come, you have been looking for the crown of dreams, everything is
            only for youto solve your troubles! Everything about us is open to
            you,please leaveyour message,or follow us to findwhere weare,XITITI
            - where we have beenwaiting for you!
          </p>
          <p class="contact_one">邮编：518000</p>
          <p class="contact_one">电话：0775-83219759</p>
          <p class="contact_one">
            地址：广东省深圳市福田区桥香路桥城东路2032号23楼
          </p>
        </div>
      </div>
    </div>
    <div class="max">
      <img
        src="https://xititimalloss.oss-cn-hangzhou.aliyuncs.com/20230130/dedf5dab12a15c42e3f73ec8800d62a3.png"
        alt=""
        class="Map"
      />
    </div>
  </div>
</template>
<script>
import { Toast } from "vant";
export default {
  name: "contact",
  data() {
    return {
      name:'',//用户姓名
      telephone:'',//联系电话
      title:'',//标题
      commentt:'',//评论
    };
  },

  methods: {
      // 提交信息
    Submit() {
      if (this.name == "") {
        Toast("请输入姓名");
      } else if (this.telephone == "") {
        Toast("请输入手机号");
      } else if (this.telephone.length < 11) {
        Toast("请输入正确的手机号");
      } else if (this.title == "") {
        Toast("请输入标题");
      } else if (this.commentt == "") {
        Toast("请输入评论");
      } else {
        this.$ajax
          .comment({
            name: this.name,
            phone: this.telephone,
            title: this.title,
            comment: this.commentt,
          })
          .then((res) => {
            if (res.code == 200) {
              Toast(res.data);
              this.name = "";
              this.telephone = "";
              this.title = "";
              this.commentt = "";
              // console.log(res);
            } else {
              Toast(res.msg || res.message);
            }
          });
      }
    },
  },

  created() {},
};
</script>
<style scoped>
/* scoped-样式只在本组件使用 */

/* 联系我们页面 */
.contact {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  padding-left: 177px;
  padding-right: 194px;
  box-sizing: border-box;
}
.contact_line {
  width: 44px;
  height: 4px;
  background: #333333;
  border-radius: 2px;
  margin-right: 40px;
}

.contact_p {
  margin: 0;
  font-size: 18px;
  font-weight: 500;
  color: #282b31;
  line-height: 35px;
}
.contact_top {
  margin-top: 61px;
  display: flex;
  align-items: center;
}
.obtain {
  margin-top: 35px;
  margin-bottom: 0;
  font-size: 42px;
  font-weight: 500;
  color: #282b31;
  line-height: 35px;
  margin-left: 84px;
}
.information {
  display: flex;
  justify-content: center;
}

.information_left {
  margin-right: 49px;
  margin-top: 87px;
  display: flex;
  flex-direction: column;
}
.name {
  width: 471px;
  height: 50px;
  background: #f8f7f7;
  border-radius: 4px;
  border: none;
  padding-left: 20px;
  margin-right: 38px;
  box-sizing: border-box;
  outline: none;
}
.contact_Telephone {
  width: 471px;
  height: 50px;
  background: #f8f7f7;
  border-radius: 4px;
  border: none;
  padding-left: 20px;
  box-sizing: border-box;
  outline: none;
  /* -moz-appearance: textfield; */
}
.my_title {
  margin-top: 29px;
  margin-bottom: 42px;
  padding: 0;
  width: 980px;
  max-width: 980px;
  height: 50px;
  background: #f8f7f7;
  border-radius: 4px;
  border: none;
  padding-left: 20px;
  text-align: left;
  box-sizing: border-box;
  outline: none;
}

.commentt {
  padding-top: 22px;
  width: 980px;
  height: 200px;
  background: #f8f7f7;
  border-radius: 4px;
  border: none;
  padding-left: 20px;
  box-sizing: border-box;
  flex-wrap: wrap;
  outline: none;
  resize: none;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.Submit {
  cursor: pointer;
  width: 260px;
  height: 78px;
  background: #bc1313;
  border-radius: 4px;
  color: #fff;
  font-size: 24px;
  text-align: center;
  line-height: 78px;
  margin-top: 38px;
}
.information_right {
  width: 520px;
}
.English {
  margin-top: 98px;
  font-size: 16px;
  font-weight: 400px;
  color: #333;
  line-height: 24px;
  margin-bottom: 67px;
}
.contact_one {
  line-height: 44px;
  margin: 0;
}
.Map {
  margin-top: 39px;
  max-width: 100%;
  /* height: 881px; */
  display: block;
}

</style>