<template>
  <!-- 首页 -->
  <div>
    <img
      class="img"
      src="https://xititimalloss.oss-cn-hangzhou.aliyuncs.com/20230130/90c6176321ca92dcb5deb2bb82292b78.png"
      alt=""
    />
    <div class="introduce">
      <div class="body_top">
        <div @click="toSteward">
          <img
            src="https://xititimalloss.oss-cn-hangzhou.aliyuncs.com/20230403/ca67bc042945b73eef13ea71ca3bc17d.png"
            alt=""
          />
        </div>
        <div @click="toMerchant">
          <img
            src="https://xititimalloss.oss-cn-hangzhou.aliyuncs.com/20230403/1e4614c95c86b73f6e925740cf286c78.png"
            alt=""
          />
        </div>
        <div @click="toIntroduce">
          <img
            src="https://xititimalloss.oss-cn-hangzhou.aliyuncs.com/20230403/6521ed4cd762397d3fcaec98a91c8c09.png"
            alt=""
          />
        </div>
      </div>

      <!-- logo -->
    </div>

    <div class="serto">
      <div class="small_serto">
        <div :class="['in_logo', scroll > '375' ? 'show-animate' : '']"></div>
        <div :class="['service', scroll > '450' ? 'service_new' : '']">
          <div class="consume">消费购物新体验，全方位一站式服务</div>
          <div class="all_small">
            <div
              :class="[
                'introduce_small category show-mate',
                num == index ? 'text-part-picked' : '',
              ]"
              v-for="(item, index) in introduce"
              :key="index"
              @mouseenter="onEnterTd(index)"
            >
              <img class="in_sm_logo" :src="item.logo" alt="" />
              <div class="in_sm_right">
                <p class="right_top">
                  {{ item.title }}
                </p>
                <p class="right_foot">{{ item.con }}</p>
              </div>
            </div>
          </div>
        </div>
        <img
          :class="['iphone', scroll > '450' ? 'iphone111' : '']"
          :src="imagelist[num]"
          alt=""
        />
      </div>
    </div>

    <!-- 合作案例 -->
    <div class="cooperate">
      <div :class="['co_logo', scroll > 1055 ? 'co_logo11' : '']"></div>

      <div
        v-if="caselist != ''"
        :class="['coop_bg', scroll > 1200 ? 'coop_bg111' : '']"
      ></div>

      <div
        v-if="caselist != ''"
        :class="['Rotation', scroll > 1280 ? 'Rotation111' : '']"
      >
        <div>
          <el-carousel
            height="260px"
            :interval="5000"
            arrow="always"
            trigger="click"
            indicator-position="outside"
            ref="cardShow"
          >
            <el-carousel-item v-for="(item, idx) in caselist" :key="idx">
              <img
                class="coo_img"
                v-if="item.status == 1"
                @click="goHyperlink(item.page_url)"
                :src="item.img_url"
                alt=""
              />
            </el-carousel-item>
          </el-carousel>
        </div>

        <img
          class="up"
          @click="arrowClick('left')"
          src="https://xititimalloss.oss-cn-hangzhou.aliyuncs.com/20230329/522cd656e1c78c0aac442e85903ab67e.png"
          alt=""
        />
        <img
          class="down"
          @click="arrowClick('right')"
          src="https://xititimalloss.oss-cn-hangzhou.aliyuncs.com/20230329/bc24403ad5b18dd54984a0f6787fa38b.png"
          alt=""
        />
      </div>

      <img
        v-else
        :class="['rotimg', scroll > 1280 ? 'rotimg111' : '']"
        src="https://xititimalloss.oss-cn-hangzhou.aliyuncs.com/20230410/6f65ffe64a3e1f6611971b8c246ad72a.png"
        alt=""
      />
    </div>
    <!-- 新闻中心 -->
    <div class="news">
      <div :class="['news_logo', scroll > 1700 ? 'news_logo111' : '']"></div>
      <div
        v-if="newsList != ''"
        :class="['news_con', scroll > 1700 ? 'news_con111' : '']"
      >
        <div class="bgcolor"></div>
        <div class="news_Rot">
          <el-carousel
            height="382px"
            direction="vertical"
            :autoplay="true"
            trigger="click"
            indicator-position="outside"
            style="width: 314px"
          >
            <el-carousel-item
              style="width: 288px"
              v-for="(item, dex) in newsList"
              :key="dex"
            >
              <img
                class="news_img"
                @click="gonewsDetail(item.id)"
                :src="item.img_url"
                alt=""
              />
            </el-carousel-item>
          </el-carousel>
        </div>

        <div class="six">
          <div class="news_report">新闻中心报道</div>
          <div class="news_small">
            <div style="width: 500px">
              <div
                @click="gonewsDetail(dex.id)"
                class="namelist"
                v-for="(dex, index) in newsList"
                :key="index"
              >
                <img :src="dex.numimg" alt="" />
                <div>{{ dex.name }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="All" @click="goNews">全部报道 ＞</div>
      </div>

      <img
        v-else
        :class="['newsimg', scroll > 1800 ? 'newsimg111' : '']"
        src="https://xititimalloss.oss-cn-hangzhou.aliyuncs.com/20230410/99c5525f031ba8cd90266e2877dce8a9.png"
        alt=""
      />
    </div>
  </div>
</template>
<script>
import { Swipe, SwipeItem } from "vant";
export default {
  name: "home",
  data() {
    return {
      // 手机轮播图
      imagelist: [
        "https://xititimalloss.oss-cn-hangzhou.aliyuncs.com/20230403/2a95ee18b492dd64edfc33a5e514b415.png",
        "https://xititimalloss.oss-cn-hangzhou.aliyuncs.com/20230403/dba700672a1f13504bf498d0bb04f59a.png",
        "https://xititimalloss.oss-cn-hangzhou.aliyuncs.com/20230403/cb7414b4baf8ab817b46c42e710cb77c.png",
        "https://xititimalloss.oss-cn-hangzhou.aliyuncs.com/20230403/073eb0cb3a961d473d68944f99563720.png",
      ],

      // 服务介绍
      introduce: [
        {
          logo: "https://xititimalloss.oss-cn-hangzhou.aliyuncs.com/20230327/ecec57695decafccb4da0c983eb69b29.png",
          title: "首页 Home",
          con: "品类全覆盖，应有尽有",
        },
        {
          logo: "https://xititimalloss.oss-cn-hangzhou.aliyuncs.com/20230327/8440339aa40f01e044c7c8d8033342cd.png",
          title: "喜店 Hi shop",
          con: "查看附近喜店，快捷消费",
        },
        {
          logo: "https://xititimalloss.oss-cn-hangzhou.aliyuncs.com/20230327/f04c82b9e6c479c4836eebcb118588ee.png",
          title: "收益 Earnings",
          con: "详细查看每一笔收益状态",
        },
        {
          logo: "https://xititimalloss.oss-cn-hangzhou.aliyuncs.com/20230327/f8ca03b4806a05ba7e583f7b1ea31bf7.png",
          title: "喜圈 Hi Circle",
          con: "全方位赋能商家、生活管家发展",
        },
      ],
      //序号
      order_num: [
        "https://xititimalloss.oss-cn-hangzhou.aliyuncs.com/20230410/bdb6b79a2bd9cb23bfd30a4063fb92ec.png",
        "https://xititimalloss.oss-cn-hangzhou.aliyuncs.com/20230410/e2ac580c3465a4e1371099c97a707e8b.png",
        "https://xititimalloss.oss-cn-hangzhou.aliyuncs.com/20230410/2b368a8564d976e40edae8da9da658ae.png",
        "https://xititimalloss.oss-cn-hangzhou.aliyuncs.com/20230410/fef8cbee1691b906fb94017e2ded7239.png",
        "https://xititimalloss.oss-cn-hangzhou.aliyuncs.com/20230410/af4b2a033ae51263413ad47b8b80194b.png",
        "https://xititimalloss.oss-cn-hangzhou.aliyuncs.com/20230410/7c47b589789ecb4a21907f0c8085063f.png",
      ],

      num: "0",
      scroll: "",
      caselist: "", //合作案例列表
      newsList: "", //新闻列表
      timer: "",
    };
  },

  methods: {
    // 优秀合作案例
    caseList() {
      this.$ajax.caseList().then((res) => {
        // console.log("优秀合作案例", res.data.data);
        let arr = res.data.data.filter((item) => {
          return 1 == item.status;
        });
        this.caselist = arr;
      });
    },

    // 新闻列表
    getList() {
      this.$ajax
        .getList({
          is_index_show: 1,
        })
        .then((res) => {
          var list = res.data.data.slice(0, 6);
          var num = this.order_num.slice(0, list.length);

          for (let i in list) {
            list[i]["numimg"] = num[i];
          }

          this.newsList = list;
          console.log("list", list);
        });
    },

    //跳转外部链接
    goHyperlink(url) {
      var txt = url.substring(url.lastIndexOf("."));
      if (txt == ".com") {
        let h5url = url;
        let path = window.location.protocol + "//" + h5url;
        window.location.href = path;
      } else {
        this.$router.push(url);
      }
    },

    // 跳生活管家
    toSteward() {
      this.$router.push("/steward");
    },
    // 跳商户入驻
    toMerchant() {
      this.$router.push("/Merchant");
    },
    // 跳平台介绍
    toIntroduce() {
      this.$router.push("/introduce");
    },
    start() {
      this.timer = setInterval(this.valChange, 2000); // 注意: 第一个参数为方法名的时候不要加括号;
    },
    valChange() {
      this.num++;
      if (this.num == 4) {
        this.num = 0;
      }
    },

    over() {
      clearInterval(this.timer);
    },

    goNews() {
      this.$router.push("/news");
    },
    

    gonewsDetail(id) {
      this.$router.push({
        name: "newsDetail",
        query: {
          id: id,
          type:1
        },
      });
    },

    // 轮播图切换图片
    arrowClick(val) {
      if (val === "right") {
        this.$refs.cardShow.next();
      } else {
        this.$refs.cardShow.prev();
      }
    },

    onEnterTd(ind) {
      this.num = ind;
    },

    gotoswiper(index) {
      this.$refs.swiper.swipeTo(index);
    },

    handleScroll() {
      //或者使用document.querySelector('.class或者#id').scrollTop
      this.scroll =
        document.documentElement.scrollTop || document.body.scrollTop;
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },

  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },

  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },

  created() {
    this.caseList();
    this.getList();
    this.valChange();
    this.start();
  },
};
</script>



 <style>
.el-carousel__arrow--left {
  left: -41px !important;
  background-color: rgba(31, 45, 61, 0) !important;
  font-size: 30px !important;
}
.el-carousel__arrow--right {
  right: -41px !important;
  background-color: rgba(31, 45, 61, 0) !important;
  font-size: 30px !important;
}
.el-carousel__button {
  width: 10px !important;
  height: 10px !important;
  border-radius: 50%;
}

.el-carousel__indicators--outside {
  float: right;
  position: absolute !important;
  text-align: right;
  top: 188px;
}

.el-carousel__indicators--horizontal {
  top: 300px !important;
}

.el-carousel--horizontal {
  height: 350px;
}

.el-carousel__indicators--vertical {
  top: 275px !important;
  left: 286px;
}
</style>


<style scoped>
/* scoped-样式只在本组件使用 */

.img {
  width: 1920px;
  padding-top: 105px;
}

.introduce {
  position: relative;
  width: 100%;
}

.body_top {
  position: absolute;
  display: flex;
  top: -47px;
  left: 585px;
  height: 128px;
  z-index: 888;
}

.body_top img {
  width: 251px;
  height: 128px;
  cursor: pointer;
}

/* 服务介绍 */
.serto {
  width: 1920px;
  height: 761px;
}

.small_serto {
  width: 1000px;
  height: 761px;
  position: relative;
  margin: 0 auto;
}

.in_logo {
  transition: all 0.5s;
  width: 407px;
  height: 90px;
  position: absolute;
  top: 195px;
  left: 290px;
  background: url(https://xititimalloss.oss-cn-hangzhou.aliyuncs.com/20230327/d33962ee1246906874fce06396b7acc8.png);
  background-size: cover;
  background-repeat: no-repeat;
  /* transform: translateX(-50%); */
  /* z-index: 999; */
  opacity: 0;
}

.show-animate {
  opacity: 1;
  top: 123px;
}

.service {
  transition: all 0.5s;
  position: absolute;
  left: 0;
  top: 50px;
}

.service_new {
  opacity: 1;
  top: 0;
}

.consume {
  width: 500px;
  height: 40px;
  font-size: 30px;
  color: #000002;
  font-weight: 500;
  position: absolute;
  top: 287px;
}

.all_small {
  height: 390px;
  width: 638px;
  position: absolute;
  top: 317px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-align: start;
  align-items: flex-start;
}

.text-part-picked .right_top {
  top: -50px !important;
  -webkit-transform: scale(1.5);
  -ms-transform: scale(1.5);
  transform: scale(1.5);
  -webkit-transform-origin: 0 50%;
  -ms-transform-origin: 0 50%;
  transform-origin: 0 50%;
  color: #bc1313;
}

.introduce_small {
  position: relative;
  font-size: 26px;
  /* color: #000; */
  height: 85px;
  width: 100%;
  display: -ms-flexbox;
  display: flex;
  cursor: pointer;
  opacity: 0;
  top: 100px;
  transition: all 0.2s;
}

.show-mate {
  top: 50px;
  opacity: 1;
}

.category {
  transition: all 0.2s 0.3s;
}

.right_top {
  transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;
  position: absolute;
  font-size: 26px;
  z-index: 1;
  -webkit-transform-origin: 0 100%;
  -ms-transform-origin: 0 100%;
  transform-origin: 0 100%;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  top: -32px !important;
}

.right_foot {
  transition: all 0.5s;
  font-size: 16px;
  height: 16px;
  position: absolute;
  top: 25px;
  left: 0;
}

.right_foot:before {
  transition: all 0.5s;
  content: "";
  width: 120px;
  height: 3px;
  position: absolute;

  top: -10px;
  background-color: #bc1313;
  opacity: 0;
}

.text-part-picked .right_foot,
.text-part-picked .right_foot:before {
  color: #bc1313 !important;
  opacity: 1 !important;
}

.in_sm_logo {
  width: 28px;
  height: 28px;
  position: absolute;
  transition: all 0.5s;
}

.in_sm_right {
  position: absolute;
  left: 61px;
  width: 585px;
  height: 30px;
}

.iphone {
  transition: all 0.5s;
  opacity: 0;
  width: 300px;
  height: 548px;
  position: absolute;
  top: 295px;
  right: 0px;
  z-index: 333;
}

.iphone111 {
  top: 244px;
  opacity: 1;
}

/* 优秀合作案例 */
.cooperate {
  background: url(https://xititimalloss.oss-cn-hangzhou.aliyuncs.com/20230327/760f9ee326d4c6f0176d60dacdd86266.png);
  background-repeat: no-repeat;
  width: 1920px;
  height: 673px;
  background-size: cover;
  width: 100%;
  position: relative;
}

.co_logo {
  width: 237px;
  height: 100px;
  transition: all 0.5s;
  position: absolute;
  top: 120px;
  left: 842px;
  background: url(https://xititimalloss.oss-cn-hangzhou.aliyuncs.com/20230327/45377b416e56d1f798e3a35b48ca57d0.png);
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 0;
}
.co_logo11 {
  opacity: 1;
  top: 69px;
}

.coop_bg {
  width: 570px;
  height: 320px;
  background-color: #840a0a;
  position: absolute;
  top: 226px;
  left: 762px;
  transition: all 0.5s;
  opacity: 0;
}

.coop_bg111 {
  opacity: 1;
  position: absolute;
  top: 206px;
}

.rotimg {
  width: 834px;
  height: 370px;
  transition: all 0.5s;
  position: absolute;
  top: 256px;
  right: 543px;
  opacity: 0;
}

.rotimg111 {
  position: absolute;
  opacity: 1;
  top: 206px;
}

.Rotation {
  width: 720px;
  height: 260px;
  position: absolute;
  top: 286px;
  left: 585px;
  opacity: 0;
  transition: all 0.5s;
}
.Rotation111 {
  opacity: 1;
  position: absolute;
  top: 236px;
}

.coo_img {
  width: 720px;
  height: 260px;
  cursor: pointer;
}

.up {
  width: 14px;
  height: 24px;
  position: absolute;
  top: 118px;
  left: -27px;
  cursor: pointer;
}
.down {
  width: 14px;
  height: 24px;
  position: absolute;
  top: 118px;
  right: -58px;
  cursor: pointer;
}

.my-swipe {
  width: 720px;
  height: 260px;
}

.my-swipe .van-swipe-item {
  color: #fff;
  font-size: 20px;
  line-height: 150px;
  text-align: center;
  background-color: #39a9ed;
}

/* 新闻中心 */
.news {
  width: 100%;
  height: 661px;
  background-color: #fff;
  position: relative;
}

.news_logo {
  width: 201px;
  height: 89px;
  background: url(https://xititimalloss.oss-cn-hangzhou.aliyuncs.com/20230329/e06f50e89a38208de3e976d8feb604b8.png);
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  top: 100px;
  left: 860px;
  transition: all 0.5s;
  opacity: 0;
}

.news_logo111 {
  position: absolute;
  top: 50px;
  opacity: 1;
}

.bgcolor {
  width: 288px;
  height: 382px;
  background-color: #9c0c0c;
  position: absolute;
  top: 23px;
  left: 28px;
}

.news_Rot {
  width: 288px;
  height: 382px;
}

.news_img {
  width: 288px;
  height: 382px;
  cursor: pointer;
}

.news_con {
  width: 50%;
  height: 473px;
  position: absolute;
  top: 238px;
  left: 592px;
  transition: all 0.5s;
  opacity: 0;
}

.news_con111 {
  opacity: 1;
  position: absolute;
  top: 188px;
}

.vecal {
  width: 289px;
  height: 382px;
  position: absolute;
  top: 0;
}

.vanitem {
  width: 289px;
  height: 382px;
}

.news_report {
  font-size: 24px;
  color: #000002;
  font-weight: 550;
  margin-bottom: 20px;
  line-height: 1;
}

.six {
  position: absolute;
  top: 0px;
  left: 405px;
}

.news_small {
  display: flex;
  height: 210px;
}

.der_num {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.der_num img {
  width: 22px;
  height: 16px;
  margin-bottom: 29px;
  margin-right: 5px;
}

.namelist {
  display: flex;
  align-items: center;
  height: 42px;
}

.namelist img {
  width: 22px;
  height: 16px;
  margin-right: 5px;
  margin-top: 4px;
}

.namelist div {
  font-size: 14px;
  color: #333;
  font-weight: 500;
  width: 500px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
}

.namelist div:hover {
  font-size: 18px;
  font-weight: 600;
}

.All {
  font-size: 14px;
  position: absolute;
  bottom: 122px;
  left: 405px;
  cursor: pointer;
}
.All:hover {
  color: #bc1313;
}

.newsimg {
  width: 705px;
  height: 412px;
  transition: all 0.5s;
  position: absolute;
  top: 238px;
  right: 623px;
  opacity: 0;
}

.newsimg111 {
  opacity: 1;
  position: absolute;
  top: 188px;
}

/* element组件 */
.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 300px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
</style>
