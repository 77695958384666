import request from './request'
const { get, post, put, del, blob } = request

//用户提交信息
const comment = (param) => post('api/pc/comment', param)
//获取token
const getToken = (param) => post('api/getToken', param)
// 新闻列表
const getList = (param) => post('/business/newsContent/getList', param)
// 优秀合作案例
const caseList = (param) => post('/business/case/getList', param)
//新闻详情
const getRow = (param) => post('/business/newsContent/getRow', param)

export default {
    comment,
    getToken,
    getList,
    caseList,
    getRow,
}