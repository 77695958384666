<template>
  <!-- 新闻中心 -->
  <div>
    <img
      class="img"
      src="https://xititimalloss.oss-cn-hangzhou.aliyuncs.com/20230410/ff9d1c2db13f976285e6e836c485f4fe.png"
      alt=""
    />
    <div class="body">
      <div class="body_top">
        <div :class="type == 1 ? 'trends' : 'news_flash'" @click="trends(1)">
          行业动态
        </div>
        <div style="width: 98px"></div>
        <div :class="type == 2 ? 'trends' : 'news_flash'" @click="trends(2)">
          喜提快讯
        </div>
      </div>
      <div v-if="newsList != ''">
        <div
          class="small"
          v-for="ind in newsList.data"
          :key="ind"
          @click="godetail(ind.id)"
        >
          <img :src="ind.list_img" alt="" />
          <div class="tit_time">
            <div class="title">{{ ind.name }}</div>
            <div class="time">
              <img
                class="clocks"
                src="https://xititimalloss.oss-cn-hangzhou.aliyuncs.com/20230406/3bcf61f14ac10cf48e9e02c615f32ebd.png"
                alt=""
              />
              <div class="start_time">{{ ind.time }}</div>
            </div>
          </div>
          <p>{{ ind.desc }}</p>
        </div>
      </div>

      <div class="default" v-else>
        <img
          class="default"
          src="https://xititimalloss.oss-cn-hangzhou.aliyuncs.com/20230407/1842393be75db072ac012f0b920d1878.png"
          alt=""
        />
        <div class="not_have">暂无新闻哦~</div>
      </div>

      <div class="paging">
        <div class="page" @click="number('home')">首页</div>
        <el-pagination
          prev-text="上一页"
          next-text="下一页"
          background
          :current-page="page"
          layout="prev, pager, next"
          :total="newsList.total"
          @current-change="handleCurrentChange"
          :page-size="limit"
        >
        </el-pagination>
        <div class="page" @click="number('end')">末页</div>
      </div>
    </div>
  </div>
</template>
<script>
import { Toast } from "vant";
export default {
  name: "news",
  data() {
    return {
      currentPage: 1,
      type: 1, //行业动态||喜提快讯
      newsList: "", //新闻列表
      limit: 4,
      page: 1, //页码
    };
  },

  methods: {
    godetail(inde) {
      this.$router.push({
        name: "newsDetail",
        query: {
          id: inde,
        },
      });
    },
    number(num) {
      console.log("页码", num);
      if (num == "home") {
        this.page = 1;
      } else {
        this.page = this.newsList.last_page;
      }
      this.getList();
    },
    getList() {
      this.$ajax
        .getList({
          type: this.type,
          limit: this.limit,
          page: this.page,
        })
        .then((res) => {
          if (res.code == 200) {
            // this.newsList = res.data;
            var List = res.data;
            console.log(List);
            for (var i in List.data) {
              var date = List.data[i].created_at;
              var time = new Date(date * 1000);

              let y = time.getFullYear(); // 年
              let MM = time.getMonth() + 1; // 月
              let d = time.getDate(); // 日
              
              MM = MM < 10 ? "0" + MM : MM;
              d = d < 10 ? "0" + d : d;
              let timer = y + "-" + MM + "-" + d;
              console.log(timer);
              List.data[i]['time'] = timer
              console.log(List);
              this.newsList = List
            }
          } else {
            Toast(res.msg || res.message);
          }
        });
    },
    trends(e) {
      console.log("e", e);
      this.newsList = "";
      this.type = e;
      this.page = 1;
      this.getList();
    },

    handleCurrentChange(val) {
      this.page = val;
      this.getList();
      console.log("val", val);
    },
  },

  created() {
    this.getList();
  },
};
</script>
<style>
.el-pager li {
  font-size: 22px !important;
  height: 48px !important;
  width: 48px !important;
  line-height: 48px !important;
  font-weight: 500 !important;
  margin: 0 13px !important;
}

.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #bc1313 !important;
  color: #fff;
}

.el-pager li:hover {
  color: #bc1313 !important;
}

.el-pagination span:not([class*="suffix"]) {
  font-size: 22px !important;
}

.el-pagination button {
  width: 128px !important;
  height: 48px !important;
}
</style>
<style scoped>
/* scoped-样式只在本组件使用 */

.img {
  width: 1920px;
  padding-top: 105px;
}

.trends {
  width: 200px;
  height: 68px;
  background: #bc1313;
  border-radius: 6px;
  font-size: 30px;
  color: #fff;
  text-align: center;
  line-height: 68px;
  cursor: pointer;
}

.news_flash {
  width: 202px;
  height: 70px;
  border: 2px solid #bc1313;
  border-radius: 6px;
  font-size: 30px;
  text-align: center;
  line-height: 68px;
  box-sizing: border-box;
  cursor: pointer;
}

.body_top {
  margin-top: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 73px;
}
.small {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 216px 32px;
  padding-bottom: 32px;
  box-sizing: border-box;
  position: relative;
  border-bottom: 1px solid #eeeeee;
  cursor: pointer;
}
.small img {
  width: 421px;
  height: 256px;
}
.small p {
  width: 1037px;
  height: 66px;
  font-size: 24px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #666666;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.tit_time {
  display: flex;
  align-items: center;
  position: absolute;
  top: 34px;
  left: 451px;
  justify-content: space-between;
}

.title {
  font-size: 28px;
  color: #333;
  font-weight: bold;

  width: 850px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.time {
  display: flex;
  align-items: center;
}

.clocks {
  width: 19px !important;
  height: 19px !important;
  margin-right: 11px;
}
.start_time {
  font-size: 24px;
  color: #999;
}

.default {
  width: 1920px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 120px;
  margin-bottom: 175px;
}

.default img {
  width: 486px;
  height: 430px;
  margin-bottom: 83px;
}

.not_have {
  font-size: 30px;
  color: #999;
  font-weight: 500;
}

.paging {
  width: 1920px;
  display: flex;
  justify-content: center;
  margin-bottom: 55px;
}

.page {
  width: 128px;
  height: 48px;
  border: 1px solid #eeeeee;
  font-size: 22px;
  font-weight: 500px;
  text-align: center;
  line-height: 48px;
  margin: 0 27px;
  cursor: pointer;
}
</style>