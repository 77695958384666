<template>
  <div>
    <div class="max">
      <div class="top">
        <img
          src="https://xititimalloss.oss-cn-hangzhou.aliyuncs.com/20230201/7326ec66903f3329c9c8115a56a76006.png"
          alt=""
          class="LOGO"
        />
        <div class="top_small">
          <router-link to="/home" class="small" @click.native="Button('0')">
            <div class="small_p">首页</div>
            <div class="Line" v-if="index == 0"></div>
          </router-link>
          <router-link to="/join" class="small" @click.native="Button('1')">
            <div class="small_p">入驻加盟</div>
            <div class="Line" v-if="index == 1"></div>
          </router-link>
          <router-link to="/survey" class="small" @click.native="Button('2')">
            <div class="small_p">公司概况</div>
            <div class="Line" v-if="index == 2"></div>
          </router-link>
          <router-link
            to="/introduce"
            class="small"
            @click.native="Button('3')"
          >
            <div class="small_p">平台介绍</div>
            <div class="Line" v-if="index == 3"></div>
          </router-link>
          <router-link to="/news" class="small" @click.native="Button('4')">
            <div class="small_p">新闻中心</div>
            <div class="Line" v-if="index == 4"></div>
          </router-link>
          <router-link to="/contact" class="small" @click.native="Button('5')">
            <div class="small_p">联系我们</div>
            <div class="Line" v-if="index == 5"></div>
          </router-link>
        </div>
      </div>
      <img
        class="img5"
        v-show="index == 5"
        src="https://xititimalloss.oss-cn-hangzhou.aliyuncs.com/20230410/df41d2d0fdfc5fba1d637141f005c59a.png"
        alt=""
      />
    </div>
  </div>
</template>
<script>
export default {
  name: "",
  data() {
    return {
      index: 0, //导航索引
    };
  },

  methods: {
    // 导航的点击事件
    Button(e) {
      this.index = e;
    },
  },

  watch: {
    $route(to, from) {
      console.log(this.$route);
      if (this.$route.name == "home") {
        this.index = 0;
      }
      if (
        this.$route.name == "join" ||
        this.$route.name == "steward" ||
        this.$route.name == "Merchant"
      ) {
        this.index = 1;
      }
      if (this.$route.name == "survey") {
        this.index = 2;
      }
      if (this.$route.name == "introduce") {
        this.index = 3;
      }
      if (this.$route.name === "news" || this.$route.name == "newsDetail") {
        this.index = 4;
      }
      if (this.$route.name == "contact") {
        this.index = 5;
      }
    },
  },

  created() {
    console.log(this.$route);
      if (this.$route.name == "home") {
        this.index = 0;
      }
      if (
        this.$route.name == "join" ||
        this.$route.name == "steward" ||
        this.$route.name == "Merchant"
      ) {
        this.index = 1;
      }
      if (this.$route.name == "survey") {
        this.index = 2;
      }
      if (this.$route.name == "introduce") {
        this.index = 3;
      }
      if (this.$route.name === "news" || this.$route.name == "newsDetail") {
        this.index = 4;
      }
      if (this.$route.name == "contact") {
        this.index = 5;
      }
  },
};
</script>
<style scoped>
/* scoped-样式只在本组件使用 */
.max {
  width: 1920px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.top {
  position: fixed;
  z-index: 999;
  padding-left: 228px;
  padding-right: 207px;
  max-width: 100%;
  box-sizing: border-box;
  max-height: 105px;
  background-color: #ab0e0e;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}

.LOGO {
  /* margin-left: 228px; */
  width: 268px;
  height: 64px;
  margin-right: 117px;
}

.top_small {
  width: 1100px;
  display: flex;
}

.small {
  height: 105px;
  width: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.small_p {
  height: 100px;
  font-size: 20px;
  color: #fff;
  line-height: 100px;
}
.Line {
  width: 81px;
  height: 3px;
  background: #ffffff;
  border-radius: 1px;
}
.img {
  padding-top: 105px;
}
.img1 {
  width: 1920px;
  padding-top: 105px;
}
.img4 {
  width: 1920px;
  padding-top: 105px;
}

.img5 {
  width: 1920px;
  padding-top: 105px;
}
</style>