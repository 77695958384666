<template>
<!-- 公司概况 -->
  <div class="page">
        <img class="img" src="https://xititimalloss.oss-cn-hangzhou.aliyuncs.com/20230130/90c6176321ca92dcb5deb2bb82292b78.png" alt="">
    <div class="body">
      <div class="body_one">
        <div>
          <div class="title">
            <div class="title_p">企业宗旨</div>
            <img
              src="https://xititimalloss.oss-cn-hangzhou.aliyuncs.com/20230201/4215f51cb5eaa90af5b02cc036477073.png"
              alt=""
              class="title_img"
            />
          </div>
          <div class="content">
            <img
              src="https://xititimalloss.oss-cn-hangzhou.aliyuncs.com/20230201/38ade6fb0fe65b841d82ed5dc35ad390.png"
              alt=""
              class="purpose"
            />
            <div class="purpost_p">
              依托互联网、大数据、云计算，以用户为中心构建，贯彻国家融合线上线下消费双循环；有效兼顾消费者与商家需求，为商家实现多场景深度融合，为消费者实现一次消费多次收益；致力于发展数字化、生态化建设，立足全国，展望全球，赋能新零售
              ，重塑千亿市场格局。
            </div>
          </div>
        </div>
      </div>
      <!-- 愿景使命 -->
      <div class="mission">
        <div class="title_two">
          <div class="title_p">愿景使命</div>
          <img
            src="https://xititimalloss.oss-cn-hangzhou.aliyuncs.com/20230201/337cecdbb15b1501776a8623f1239c33.png"
            alt=""
            class="title_img"
          />
        </div>
        <div class="mission_p">
          融合线上线下消费双循环，实现门店创客化，客户平台化。
        </div>
        <div class="mission_img">
          <img
            class="missionimg"
            src="https://xititimalloss.oss-cn-hangzhou.aliyuncs.com/20230201/50d35f46e957e41f5e6152e3c02d1f65.png"
            alt=""
          />
          <img
            class="missionimg nth-child"
            src="https://xititimalloss.oss-cn-hangzhou.aliyuncs.com/20230201/b35505baaa7517ecd2a1dd1052ae8e50.png"
            alt=""
          />
          <img
            class="missionimg"
            src="https://xititimalloss.oss-cn-hangzhou.aliyuncs.com/20230201/c0bf3fd05fbf72ffbb4eb4f27cba09f7.png"
            alt=""
          />
        </div>
      </div>
      <div>
        <div class="title_three">
          <div class="title_p">企业精神/核心价值观</div>
          <img
            src="https://xititimalloss.oss-cn-hangzhou.aliyuncs.com/20230201/495f4b9803c01ced3f13776d6d8eaef8.png"
            alt=""
            class="titleimg_three"
          />
        </div>
        <div class="coree">
          <div class="core">
            <div class="big_one">1</div>
            <div class="core_p">
              基于本地商家，构建多元化、 开放式的一站式本地服务平台。
            </div>
            <img
              src="https://xititimalloss.oss-cn-hangzhou.aliyuncs.com/20230201/2899649e8c85b6f91b79e0a44902008a.png"
              alt=""
              class="core_right"
            />
          </div>
          <div class="core">
            <img
              src="https://xititimalloss.oss-cn-hangzhou.aliyuncs.com/20230201/7677dff14d947a4afa4e0c79e1d44fe0.png"
              alt=""
              class="core_left"
            />
            <div class="big_two">2</div>
            <div class="core_p2">
              深度开展异业联盟，打破用户增 长瓶颈，为实体商家降本增效。
            </div>
          </div>
        </div>
      </div>
      <div class="service_to">
        <div class="title_fore">
          <div class="title_p">服务理念</div>
          <img
            src="https://xititimalloss.oss-cn-hangzhou.aliyuncs.com/20230201/337cecdbb15b1501776a8623f1239c33.png"
            alt=""
            class="title_img"
          />
        </div>
        <div class="mission_p">以服务为基础，以增量为目标，以流量谋发展。</div>
      </div>
    </div>
    <!-- 产品认证 -->
    <div class="big_img">
      <img
        v-show="id == 0"
        src="https://xititimalloss.oss-cn-hangzhou.aliyuncs.com/20230130/ee25b1ac2d63ee46033b8b93b250666a.png"
        alt=""
        class="bgimg"
      />
      <img
        v-show="id == 1"
        src="https://xititimalloss.oss-cn-hangzhou.aliyuncs.com/20230130/d9c8dc1cdbedf0d13215d7dabb22741c.png"
        alt=""
        class="bgimg"
      />
      <img
        v-show="id == 2"
        src="https://xititimalloss.oss-cn-hangzhou.aliyuncs.com/20230130/3081301f3fc5d15f7a7c482ef992515c.png"
        alt=""
        class="bgimg"
      />
      <img
        v-show="id == 3"
        src="https://xititimalloss.oss-cn-hangzhou.aliyuncs.com/20230130/a5517a52823eac4d3e3b5d9f5f4d0b0a.png"
        alt=""
        class="bgimg"
      />

      <div class="choice">
        <div class="choice_small">
          <img
            @click="num('0')"
            v-if="id == 0"
            src="https://xititimalloss.oss-cn-hangzhou.aliyuncs.com/20230201/752af758acadec06ed28a865db51cc3d.png"
            alt=""
          />
          <img
            @click="num('0')"
            v-else
            src="https://xititimalloss.oss-cn-hangzhou.aliyuncs.com/20230201/c31720435394bbaf7e8e7c842619f2cc.png"
            alt=""
          />
        </div>
        <div class="choice_small">
          <img
            @click="num('1')"
            v-if="id == 1"
            src="https://xititimalloss.oss-cn-hangzhou.aliyuncs.com/20230201/fe14c05cc4f98512b9c0813075479659.png"
            alt=""
          />
          <img
            @click="num('1')"
            v-else
            src="https://xititimalloss.oss-cn-hangzhou.aliyuncs.com/20230201/22b638755061b47c7355da155f543719.png"
            alt=""
          />
        </div>
        <div class="choice_small">
          <img
            @click="num('2')"
            v-if="id == 2"
            src="https://xititimalloss.oss-cn-hangzhou.aliyuncs.com/20230201/96e4cc4b50efb1fb7fa05e1f24db1d14.png"
            alt=""
          />
          <img
            @click="num('2')"
            v-else
            src="https://xititimalloss.oss-cn-hangzhou.aliyuncs.com/20230201/d23cbc7a6cd6945947768b0305bdf49a.png"
            alt=""
          />
        </div>
        <div class="choice_small">
          <img
            @click="num('3')"
            v-if="id == 3"
            src="https://xititimalloss.oss-cn-hangzhou.aliyuncs.com/20230201/3537c428cb0404ff4c3d3b70396c1174.png"
            alt=""
          />
          <img
            @click="num('3')"
            v-else
            src="https://xititimalloss.oss-cn-hangzhou.aliyuncs.com/20230201/5975719a1195146165e8f17d53915211.png"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "survey",
  data() {
    return {
      id: 0, //产品认证索引
    };
  },

  methods: {
    //产品认证点击事件
    num(id) {
      this.id = id;
    },
  },
  created() {},
};
</script>
<style scoped>
.img {
  width: 1920px;
  padding-top: 105px;
}
.body {
  background-color: #fff;
  width: 100%;
  box-sizing: border-box;
}

.body_one {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.new {
  margin-top: 43px;
  width: 1410px;
  height: 88px;
  background: #bc1313;
  display: flex;
  justify-content: space-between;
}

.Notice {
  width: 43px;
  height: 35px;
  margin-left: 41px;
  margin-right: 24px;
}
.new_left {
  display: flex;
  align-items: center;
}

.newleft_p {
  font-size: 42px;
  color: #fff;
}

.new_right {
  display: flex;
  align-items: center;
}

.newright_p {
  font-size: 30px;
  color: #fff;
}

.right {
  font-size: 22px;
  color: #fff;
  margin-right: 41px;
  margin-left: 15px;
}

.title {
  margin-top: 56px;
  text-align: center;
  margin-bottom: 55px;
}
.title_img {
  width: 155px;
  height: 9px;
}

.title_p {
  margin-bottom: 9px;
  font-size: 38px;
  font-weight: bold;
  color: #333333;
}

.content {
  height: 538px;
  max-width: 100%;
  display: flex;
  margin-bottom: 78px;
}

.purpose {
  width: 544px;
  height: 538px;
  margin-right: 110px;
}

.purpost_p {
  margin-top: 76px;
  width: 749px;
  height: 318px;
  font-size: 30px;
  color: #666;
  word-wrap: break-word;
  text-indent: 2em;
  line-height: 72px;
}

.title_two {
  padding-top: 55px;
  text-align: center;
}

.mission_p {
  margin-top: 35px;
  text-align: center;
  font-size: 30px;
  color: #666;
}
.mission_img {
  margin-top: 36px;
  display: flex;
  justify-content: center;
}
.nth-child {
  margin-left: 45px;
  margin-right: 45px;
}

.missionimg {
  width: 441px;
  height: 280px;
}
.title_three {
  margin-top: 55px;
  text-align: center;
}

.titleimg_three {
  width: 356px;
  height: 9px;
  margin-bottom: 54px;
}
.coree {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.core {
  width: 1410px;
  height: 380px;
  background-color: #f7f7f7;
  position: relative;
}
.big_one {
  position: absolute;
  top: 66px;
  left: 71px;
  font-size: 189px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-style: italic;
  color: #bc1313;
  opacity: 0.05;
}
.big_two {
  position: absolute;
  top: 63px;
  left: 788px;
  font-size: 189px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-style: italic;
  color: #bc1313;
  opacity: 0.05;
}
.core_p {
  font-size: 30px;
  color: #666;
  line-height: 72px;
  width: 420px;
  height: 100px;
  word-wrap: break-word;
  position: absolute;
  top: 179px;
  left: 205px;
}
.core_p2 {
  font-size: 30px;
  color: #666;
  line-height: 72px;
  width: 420px;
  height: 100px;
  word-wrap: break-word;
  position: absolute;
  top: 177px;
  left: 926px;
}

.core_right {
  position: absolute;
  right: 0px;
  width: 701px;
  height: 380px;
}
.core_left {
  position: absolute;
  left: 0px;
  width: 708px;
  height: 380px;
}
.title_fore {
  text-align: center;
}
/* 产品认证 */
.big_img {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.bgimg {
  max-width: 100%;
}
.choice {
  width: 1416px;
  height: 270px;
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -708px;
}

.choice_small img {
  width: 345px;
  height: 270px;
  background-color: #a10606;
  cursor: pointer;
}

.mission {
  background-color: #f7f7f7;
  padding-bottom: 61px;
  width: 1920px;
}
.service_to {
  background-color: #fafafa;
  padding-bottom: 57px;
  padding-top: 55px;
  margin-top: 55px;
}
</style>