import axios from 'axios'
import router from '../router/index.js'

const url = location.origin
const isProd = /-qa|localhost|file/.test(url),
    test = /test/.test(url),
    URL = 'https://store.mall.xititi.com/' //测试接口
// URL = 'https://rpapi.yxpanda.com/' //生产接口
//    URL =  url+'/api';

axios.defaults.baseURL = URL
axios.defaults.timeout = 20000

axios.interceptors.request.use(config => {
    const authToken = window.localStorage.getItem('authToken')
    if (authToken) {
        config.headers['Authorization'] = 'Bearer ' + authToken
    }

    return config
})

axios.interceptors.response.use(response => response, error => {

    if (error.response && error.response.status) {

        error.message = error.response.data.message


    }
    return Promise.reject(error)
})


function handle(config) {
    return new Promise((resolve, reject) => {
        axios(config).then(res => {
            if (res.data.code == 10006 || res.data.code == 10001 || res.data.code == 10004) {
                Toast(res.data.msg || res.data.message);
                setTimeout(() => {
                    router.push({ name: 'login' })
                }, 1500)
                return false;
            }
            if (res.data.code == 10008) {
                Toast('账号已封');

                return false;
            }

            resolve(res.data)
        }).catch(err => {
            console.log(err)

            reject(err)
        })
    })
}

const post = (url, params) => {
    const config = {
        method: 'post',
        url
    }
    if (params) {
        config.data = params
    }

    return handle(config)
}

const get = (url, params) => {
    const config = {
        method: 'get',
        url
    }
    if (params) {
        config.params = params
    }
    return handle(config)
}


export default {
    post,
    get,
}