var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_c('div',{staticClass:"body"},[_c('div',{staticClass:"body_small",on:{"click":_vm.goSteward}},[_c('img',{attrs:{"src":"https://xititimalloss.oss-cn-hangzhou.aliyuncs.com/20230410/8dea6a5c0e20014462df927a17ad5b29.png","alt":""}}),_vm._m(1)]),_c('div',{staticClass:"body_small",on:{"click":_vm.goMerchant}},[_c('img',{attrs:{"src":"https://xititimalloss.oss-cn-hangzhou.aliyuncs.com/20230410/8fc279edff9ebebe473aefc12823e40b.png","alt":""}}),_vm._m(2)]),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bgu"},[_c('div',{staticClass:"bgu_one"},[_vm._v("合作赋能 共创美好生活")]),_c('div',{staticClass:"bgu_two"},[_vm._v("消费有喜·好物提一提")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"body_two"},[_c('div',{staticClass:"two_title"},[_vm._v("成为生活管家")]),_c('div',{staticClass:"two_Con"},[_vm._v("平台优势，成交量有保障")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"body_two"},[_c('div',{staticClass:"two_title"},[_vm._v("商户入驻")]),_c('div',{staticClass:"two_Con"},[_vm._v("不限商户，超市便利店、生鲜蔬果、"),_c('br'),_vm._v("药品鲜花招商 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"body_small"},[_c('img',{attrs:{"src":"https://xititimalloss.oss-cn-hangzhou.aliyuncs.com/20230410/0a715c24ed67a8db8d226a96324916f3.png","alt":""}}),_c('div',{staticClass:"body_two"},[_c('div',{staticClass:"two_title"},[_vm._v("本地生活服务圈")]),_c('div',{staticClass:"two_Con"},[_vm._v("推荐商家入驻，做预设生活管家"),_c('br'),_vm._v("加入喜提提本地生活服务圈，高收入高回报")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"body_small"},[_c('img',{attrs:{"src":"https://xititimalloss.oss-cn-hangzhou.aliyuncs.com/20230410/73a2e909e1340abc0f30df0fa07d7d91.png","alt":""}}),_c('div',{staticClass:"body_two"},[_c('div',{staticClass:"two_title"},[_vm._v("城市代理")]),_c('div',{staticClass:"two_Con"},[_vm._v("开放商家合作洽谈、生活管家陪跑等业务,"),_c('br'),_vm._v(" 帮助商家入驻喜提提，三方合作，共享红利")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"body_small"},[_c('img',{attrs:{"src":"https://xititimalloss.oss-cn-hangzhou.aliyuncs.com/20230410/d673d9c018ea5930343bd3442734d016.png","alt":""}}),_c('div',{staticClass:"body_two"},[_c('div',{staticClass:"two_title"},[_vm._v("喜提提服务市场")]),_c('div',{staticClass:"two_Con"},[_vm._v("为百万商家提供全品类优质服务，让生意更简单")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"body_small"},[_c('img',{attrs:{"src":"https://xititimalloss.oss-cn-hangzhou.aliyuncs.com/20230410/8274750c6750bd5d960e0aa18f8bdfae.png","alt":""}}),_c('div',{staticClass:"body_two"},[_c('div',{staticClass:"two_title"},[_vm._v("敬请期待~")])])])
}]

export { render, staticRenderFns }