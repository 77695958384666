<template>
  <!-- 生活管家 -->
  <div>
    <img
      class="top_img"
      src="https://xititimalloss.oss-cn-hangzhou.aliyuncs.com/20230410/9a15d35e0ca0b69df366b116910d321c.png"
      alt=""
    />
    <div class="superiority">
      <p class="sup_title">生活管家的优势</p>
      <div class="sup_body">
        <div class="sup_six">
          <div class="sup_small">
            <img
              src="https://xititimalloss.oss-cn-hangzhou.aliyuncs.com/20230403/3f9c16aa5c2ebbabe4be9387df81f101.png"
              alt=""
            />
            <p class="sup_onep">获取商家经营流水收益</p>
            <div class="sup_twop">
              推荐线下入驻商家1%经营流水<br />【间接推荐0.5%】
            </div>
          </div>
          <div class="sup_small">
            <img
              src="https://xititimalloss.oss-cn-hangzhou.aliyuncs.com/20230407/c9cfaf1ea0badbf721dd10d154c34a5c.png"
              alt=""
            />
            <p class="sup_onep">线上云店带货佣金收益</p>
            <div class="sup_twop">参与线上云店带货即可获得佣金收益</div>
          </div>
          <div class="sup_small">
            <img
              src="https://xititimalloss.oss-cn-hangzhou.aliyuncs.com/20230407/ac85bca7ea7cdc39272280c3215a9b32.png"
              alt=""
            />
            <p class="sup_onep">推荐生活管家佣金</p>
            <div class="sup_twop">生活管家可推荐他人成为生活管家并赚取收益</div>
          </div>
          <div class="sup_small">
            <img
              src="https://xititimalloss.oss-cn-hangzhou.aliyuncs.com/20230407/b863915fc7f163d207bf939f61fbdf7d.png"
              alt=""
            />
            <p class="sup_onep">生活管家分红权益</p>
            <div class="sup_twop">
              根据现有生活管家人数，每新增生活管家，<br />现有生活管家均获得加权分红
            </div>
          </div>
          <div class="sup_small">
            <img
              src="https://xititimalloss.oss-cn-hangzhou.aliyuncs.com/20230407/00c33b85a28405a5d00377f69f629d27.png"
              alt=""
            />
            <p class="sup_onep">公司赋能培训</p>
            <div class="sup_twop">
              公司不定期举行生活管家培训及深度介绍政策福利等
            </div>
          </div>
          <div class="sup_small">
            <img
              src="https://xititimalloss.oss-cn-hangzhou.aliyuncs.com/20230407/8862202129c3031dc92f8bd683917f1b.png"
              alt=""
            />
            <p class="sup_onep">统一物料宣发供给</p>
            <div class="sup_twop">
              公司定期提供市场宣发物料<br />一键转发无需担忧后续市场宣发烦恼
            </div>
          </div>
        </div>
        <img
          class="man"
          src="https://xititimalloss.oss-cn-hangzhou.aliyuncs.com/20230403/b3f1db951de7ba23a05fd48200207eb8.png"
          alt=""
        />
        <div class="food">
          <div class="food_title">多行业解决方案</div>
          <div class="food_body">
            <div class="food_le">
              <div class="food_left">
                <img
                  src="https://xititimalloss.oss-cn-hangzhou.aliyuncs.com/20230403/f568357eda0b8225157ab4f5d7e70a3c.png"
                  alt=""
                />
                <div>美食饮品</div>
              </div>
              <div class="food_left">
                <img
                  src="https://xititimalloss.oss-cn-hangzhou.aliyuncs.com/20230403/aa2b48bd11d04f677f84757872ffd270.png"
                  alt=""
                />
                <div>商超便利</div>
              </div>
              <div class="food_left">
                <img
                  src="https://xititimalloss.oss-cn-hangzhou.aliyuncs.com/20230403/378a9850ca7302feac271cb0b4e170c9.png"
                  alt=""
                />
                <div>生鲜果蔬</div>
              </div>
              <div class="food_left">
                <img
                  src="https://xititimalloss.oss-cn-hangzhou.aliyuncs.com/20230403/9a852c04a0e8a90948ae040ed8c7fea3.png"
                  alt=""
                />
                <div>鲜花绿植</div>
              </div>
              <div class="food_left">
                <img
                  src="https://xititimalloss.oss-cn-hangzhou.aliyuncs.com/20230403/b253224a212b97a95e964e8840ee41d0.png"
                  alt=""
                />
                <div>医药健康</div>
              </div>
            </div>
            <div class="food_right">
              <div class="food_r_title">美食饮品</div>
              <div class="food_r_Con">
                针对美食饮品行业商品时效性强、需求时段集中的特性，提供专业、定制化的宣传解决方案。
              </div>
              <div class="understand">了解详情</div>
              <div class="food_r_img">
                <img
                  class="qwer"
                  src="https://xititimalloss.oss-cn-hangzhou.aliyuncs.com/20230403/e31a2474383e5d717fdec031738b9475.png"
                  alt=""
                />
                <img
                  src="https://xititimalloss.oss-cn-hangzhou.aliyuncs.com/20230403/af2f4e0522098d2636ff9fd23e9bad20.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 不限行业入驻 -->
    <div class="unlimited">
      <div class="nul_title">不限行业入驻</div>
      <div class="nul_three">
        <div class="nul_small">
          <img
            src="https://xititimalloss.oss-cn-hangzhou.aliyuncs.com/20230403/e98eb3bcd1d3e96ccb627c32bce891c1.png"
            alt=""
          />
          <div class="nul_small_title">企业商户</div>
          <div class="nul_small_Con">
            营业执照上的主体类型一般为有限公司、有限责任公司
          </div>
        </div>
        <div class="nul_small">
          <img
            src="https://xititimalloss.oss-cn-hangzhou.aliyuncs.com/20230403/0492cd273431a124dc08bd9542b1777c.png"
            alt=""
          />
          <div class="nul_small_title">个体工商户</div>
          <div class="nul_small_Con">
            营业执照上的主体类型一般为<br />个体户、个体工商户、个体经营
          </div>
        </div>
        <div class="nul_small">
          <img
            src="https://xititimalloss.oss-cn-hangzhou.aliyuncs.com/20230403/605d6149c833a92d3c7e8cc8e7a5ca62.png"
            alt=""
          />
          <div class="nul_small_title">个人商户</div>
          <div class="nul_small_Con">
            仅有身份证、依据法律规定免工商注册的商户
          </div>
        </div>
      </div>
    </div>
    <!-- 合作生态 -->
    <div class="cooperate">
      <div class="coop_title">合作生态</div>
      <div class="coop_two_p">
        喜提提积极拓与生态伙伴合作，赋能生态伙伴，打造更完整、更强大的联盟生态
      </div>
      <div class="coop_five">
        <img
          src="https://xititimalloss.oss-cn-hangzhou.aliyuncs.com/20230403/65c0bd6c003d90bf84296f65d8ec59ac.png"
          alt=""
        />
        <img
          src="https://xititimalloss.oss-cn-hangzhou.aliyuncs.com/20230403/365d2a7daff41366eaa7ef85ad7b15c3.png"
          alt=""
        />
        <img
          src="https://xititimalloss.oss-cn-hangzhou.aliyuncs.com/20230403/c3a290ea7238a51e47bd12398ca18848.png"
          alt=""
        />
        <img
          src="https://xititimalloss.oss-cn-hangzhou.aliyuncs.com/20230403/30a969f6b06d14d960f8937326ec531c.png"
          alt=""
        />
        <img
          src="https://xititimalloss.oss-cn-hangzhou.aliyuncs.com/20230403/5316bee35cc657e31249e4c8950fee20.png"
          alt=""
        />
      </div>
    </div>
    <!-- 加入 -->
    <div class="footer">
      <div class="footer_title">喜提提期待您的加入</div>
      <img
        class="become"
        @click="showPopup"
        src="https://xititimalloss.oss-cn-hangzhou.aliyuncs.com/20230403/40950232018c51323bff213d5c0756dd.png"
        alt=""
      />
    </div>
    <van-popup round v-model="show" :close-on-click-overlay="false">
      <div class="pop">
        <div class="title">
          <div class="bridge"></div>
          <div class="life">成为生活管家</div>
        </div>
        <div class="body">
          <div class="one">1</div>
          <div class="one_con">
            <div class="one_con1">手机微信扫码喜提提小程序</div>
            <img
              src="https://xititimalloss.oss-cn-hangzhou.aliyuncs.com/20230411/0ccf172423c754c9aeeb4ce0760bf3d6.png"
              alt=""
            />
            <div class="one_con2">打开微信扫码进入喜提提</div>
          </div>
          <div class="drop">···········</div>
          <div class="two">2</div>
          <div class="two_con">
            <div class="one_con1">进入喜提提，点击“成为管家”</div>
            <img
              class="steward"
              src="https://xititimalloss.oss-cn-hangzhou.aliyuncs.com/20230407/28c7be3a188bb2fba3ed9234f72ada8d.png"
              alt=""
            />
          </div>
        </div>
        <div class="fork" @click="close">×</div>
        <div class="point">点击这里成为管家</div>
      </div>
    </van-popup>
  </div>
</template>
<script>
export default {
  name: "steward",
  data() {
    return {
      show: false,
    };
  },

  methods: {
    showPopup() {
      this.show = true;
    },
    close() {
      this.show = false;
    },
  },

  created() {},
};
</script>
<style scoped>
/* scoped-样式只在本组件使用 */

.top_img {
  width: 1920px;
  padding-top: 105px;
}
/* 生活管家的优势 */
.sup_title {
  font-size: 26px;
  font-weight: bold;
  color: #333;
  text-align: center;
  margin: 41px 0 50px;
}

.sup_six {
  width: 840px;
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
}

.sup_small {
  width: 33.3%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.sup_small img {
  width: 52px;
  height: 52px;
  margin-bottom: 22px;
}

.sup_onep {
  font-size: 18px;
  color: #333;
  font-weight: 800;
  margin: 0;
  margin-bottom: 13px;
}

.sup_twop {
  font-size: 12px;
  color: #666;
  font-weight: 500;
  margin: 0;
  white-space: nowrap;
}

.sup_small:nth-child(-n + 3) {
  margin-bottom: 70px;
}
.sup_small:nth-child(n + 3) {
  margin-bottom: 26px;
}

.man {
  width: 1920px;
  display: block;
}

.food {
  background: url(https://xititimalloss.oss-cn-hangzhou.aliyuncs.com/20230410/3b9266930efe4041784e6d90575605c2.png);
  width: 1920px;
  height: 360px;
  background-size: cover;
  display: block;
  padding-left: 509px;
  box-sizing: border-box;
}

.food_body {
  display: flex;
}

.food_title {
  font-size: 22px;
  color: #333;
  font-weight: 500;
  margin: 35px 0 38px;
}

.food_le {
  width: 125px;
  padding-right: 43px;
  border-right: 2px solid #eaeaea;
}

.food_left {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.food_left img {
  width: 18px;
  height: 18px;
  margin-left: 8px;
}

.food_left div {
  font-size: 14px;
  font-weight: 400;
}

.food_right {
  margin-left: 19px;
}

.food_r_img {
  display: flex;
}

.food_r_img img {
  width: 93px;
  height: 48px;
}

.food_r_img img:nth-child(1) {
  margin-right: 14px;
}

.food_r_title {
  font-size: 18px;
  color: #333;
  font-weight: 500;
  margin: 4px 0 21px;
}

.food_r_Con {
  font-size: 14px;
  color: #333;
}

.understand {
  width: 90px;
  height: 30px;
  background: #bc1313;
  border-radius: 4px;
  font-size: 12px;
  color: #fff !important;
  text-align: center;
  line-height: 30px;
  margin: 27px 0 39px;
}

/* 不限行业入驻 */
.unlimited {
  background: url(https://xititimalloss.oss-cn-hangzhou.aliyuncs.com/20230403/cfce6018b8dbf24732b52f1b5654cb7f.png);
  width: 1920px;
  height: 383px;
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 64px;
  box-sizing: border-box;
}

.nul_title {
  font-size: 26px;
  color: #fff;
  font-weight: 500;
  text-align: center;
  margin-bottom: 60px;
}

.nul_three {
  display: flex;
  align-items: center;
  width: 875px;
  margin: 0 auto;
}

.nul_small {
  width: 33.3%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.nul_small img {
  width: 70px;
  height: 70px;
  margin-bottom: 43px;
}

.nul_small_title {
  font-size: 18px;
  color: #fff;
  font-weight: 800;
}

.nul_small_Con {
  font-size: 12px;
  color: #fff;
  font-weight: 500;
}

/* 合作生态 */

.cooperate {
  width: 1920px;
  height: 325px;
  padding-top: 55px;
  box-sizing: border-box;
}

.coop_title {
  font-size: 24px;
  color: #333;
  font-weight: 500;
  text-align: center;
  margin-bottom: 17px;
}

.coop_two_p {
  font-size: 16px;
  color: #999;
  text-align: center;
  margin-bottom: 88px;
}

.coop_five {
  width: 830px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
}

.coop_five img {
  width: 95px;
  height: 27px;
}

/* 加入*/

.footer {
  width: 1920px;
  height: 212px;
  background-color: #bc1313;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.footer_title {
  font-size: 30px;
  color: #fff;
  font-weight: bold;
  margin-bottom: 15px;
}

.become {
  width: 155px;
  height: 53px;
  cursor: pointer;
}

/* 弹窗 */

.pop {
  width: 631px;
  height: 405px;
  padding: 68px 28px 50px;
  box-sizing: border-box;
  position: relative;
}

.title {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.bridge {
  width: 2px;
  height: 17px;
  background: #999999;
  margin-right: 11px;
}

.life {
  font-size: 17px;
  font-weight: bold;
  color: #333;
}

.body {
  width: 572px;
  height: 250px;
  background: #f7f8fa;
  border-radius: 8px;
  margin: 0 auto;
  display: flex;
}

.one {
  width: 28px;
  height: 28px;
  background: #ffffff;
  border: 2px solid #e3e3e3;
  border-radius: 50%;
  font-size: 15px;
  color: #333;
  text-align: center;
  line-height: 28px;
  font-weight: bold;
  margin: 27px 8px 0 17px;
}

.two {
  width: 28px;
  height: 28px;
  background: #ffffff;
  border: 2px solid #e3e3e3;
  border-radius: 50%;
  font-size: 15px;
  color: #333;
  text-align: center;
  line-height: 28px;
  font-weight: bold;
  margin: 27px 9px 0 0;
}

.one_con {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.one_con1 {
  font-size: 15px;
  color: #333;
  font-weight: bold;
  margin-top: 32px;
  margin-bottom: 22px;
}

.one_con img {
  width: 120px;
  height: 120px;
  margin-bottom: 22px;
}

.one_con2 {
  font-size: 15px;
  font-weight: bold;
  color: #999;
}

.drop {
  color: #999;
  font-size: 12px;
  margin: 38px 13px 0 15px;
}

.two_con img {
  width: 191px;
  height: 156px;
}

.fork {
  font-size: 30px;
  position: absolute;
  top: 25px;
  right: 30px;
  cursor: pointer;
}

.point {
  background: url(https://xititimalloss.oss-cn-hangzhou.aliyuncs.com/20230407/57f7b5783a4169452986b3b7dd7fbb6c.png);
  background-size: cover;
  background-repeat: no-repeat;
  width: 122px;
  height: 41px;
  text-align: center;
  line-height: 45px;
  color: #fff;
  font-size: 13px;
  font-weight: 500;
  position: absolute;
  top: 258px;
  right: 168px;
}
</style>