const formatNumber = n => {
    n = n.toString()
    return n[1] ? n : `0${n}`
  }
// 时间格式
const formatTime = (time) => {
    var date = new Date(time * 1000);
    const year = date.getFullYear()
    const month = date.getMonth() + 1
    const day = date.getDate()
    const hour = date.getHours()
    const minute = date.getMinutes()
    const second = date.getSeconds()
    
    return `${[year, month, day].map(formatNumber).join('-')} ${[hour, minute, second].map(formatNumber).join(':')}`
    
  }
  module.exports = {
   
    formatTime
}