<template>
  <!-- 入驻加盟 -->
  <div>
    <div class="bgu">
      <div class="bgu_one">合作赋能 共创美好生活</div>
      <div class="bgu_two">消费有喜·好物提一提</div>
    </div>
    <div class="body">
      <div class="body_small" @click="goSteward">
        <img
          class=""
          src="https://xititimalloss.oss-cn-hangzhou.aliyuncs.com/20230410/8dea6a5c0e20014462df927a17ad5b29.png"
          alt=""
        />
        <div class="body_two">
          <div class="two_title">成为生活管家</div>
          <div class="two_Con">平台优势，成交量有保障</div>
        </div>
      </div>
      <div class="body_small" @click="goMerchant">
        <img
          class=""
          src="https://xititimalloss.oss-cn-hangzhou.aliyuncs.com/20230410/8fc279edff9ebebe473aefc12823e40b.png"
          alt=""
        />
        <div class="body_two">
          <div class="two_title">商户入驻</div>
          <div class="two_Con">不限商户，超市便利店、生鲜蔬果、<br>药品鲜花招商 </div>
        </div>
      </div>
      <div class="body_small">
        <img
          class=""
          src="https://xititimalloss.oss-cn-hangzhou.aliyuncs.com/20230410/0a715c24ed67a8db8d226a96324916f3.png"
          alt=""
        />
        <div class="body_two">
          <div class="two_title">本地生活服务圈</div>
          <div class="two_Con">推荐商家入驻，做预设生活管家<br>加入喜提提本地生活服务圈，高收入高回报</div>
        </div>
      </div>
      <div class="body_small">
        <img
          class=""
          src="https://xititimalloss.oss-cn-hangzhou.aliyuncs.com/20230410/73a2e909e1340abc0f30df0fa07d7d91.png"
          alt=""
        />
        <div class="body_two">
          <div class="two_title">城市代理</div>
          <div class="two_Con">开放商家合作洽谈、生活管家陪跑等业务,<br> 帮助商家入驻喜提提，三方合作，共享红利</div>
        </div>
      </div>
      <div class="body_small">
        <img
          class=""
          src="https://xititimalloss.oss-cn-hangzhou.aliyuncs.com/20230410/d673d9c018ea5930343bd3442734d016.png"
          alt=""
        />
        <div class="body_two">
          <div class="two_title">喜提提服务市场</div>
          <div class="two_Con">为百万商家提供全品类优质服务，让生意更简单</div>
        </div>
      </div>
      <div class="body_small">
        <img
          class=""
          src="https://xititimalloss.oss-cn-hangzhou.aliyuncs.com/20230410/8274750c6750bd5d960e0aa18f8bdfae.png"
          alt=""
        />
        <div class="body_two">
          <div class="two_title">敬请期待~</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "",
  data() {
    return {};
  },

  methods: {
    goMerchant() {
      this.$router.push({ name: "Merchant" });
    },
    goSteward() {
      this.$router.push({ name: "steward" });
    },
  },
  created() {},
};
</script>
<style scoped>
/* scoped-样式只在本组件使用 */
.bgu {
  background: url(https://xititimalloss.oss-cn-hangzhou.aliyuncs.com/20230410/900efe011bc495c036dce87cfe4e903c.png);
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 347px;
  text-align: center;
  position: relative;
}

.bgu_one {
  font-size: 88px;
  color: #fff;
  font-weight: bold;
  position: absolute;
  bottom: 112px;
  left: 545px;
}

.bgu_two {
  font-size: 40px;
  color: #fff;
  font-weight: 500;
  position: absolute;
  bottom: 43px;
  left: 771px;
}

.body {
  width: 750px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  white-space: pre-wrap;
  margin: 0 auto;
  padding-top: 41px;
  box-sizing: border-box;
}

.body_small {
  width: 368px;
  height: 360px;
  background: #ffffff;
  box-shadow: 0px 0px 8px 0px rgba(51, 51, 51, 0.13);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
  cursor: pointer;
}

.body_small:hover {
  background-color: #bc1313;
}

.body_small:hover div {
  color: #fff;
}

.body img {
  width: 368px;
  height: 240px;
}

.body_two {
  height: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.two_title {
  font-size: 30px;
  color: #333;
  font-weight: 550;
  margin-bottom: 13px;
}

.two_Con {
  font-size: 16px;
  color: #999;
  font-weight: 500;
  line-height: 24px;
}
</style>