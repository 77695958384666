<template>
  <div>
    <div class="guide">
      当前位置：
      <div @click="goHome" style="cursor: pointer" v-if="type==1">首页</div>
      <div v-if="type==1">></div>
      
      <div @click="goNews" style="cursor: pointer">新闻中心</div>
      >
      <div style="cursor: pointer">新闻详情</div>
    </div>
    <div class="time">{{ detail.created_at }}</div>
    <div class="title">{{ detail.name }}</div>
    <div class="con" v-html="detail.content"></div>
  </div>
</template>
<script>
export default {
  name: "newsDetail",
  data() {
    return {
      id: "", //新闻id
      detail: "", //新闻详情
      type:'',//是否由首页跳转
    };
  },

  mounted() {},

  methods: {
// 返回首页
goHome(){
  this.$router.push("/Home");
},

// 返回新闻中心
goNews(){
  this.$router.push("/news");
},


    getRow() {
      this.$ajax
        .getRow({
          id: this.id,
        })
        .then((res) => {
          if (res.code == 200) {
            this.detail = res.data;
            var detail = res.data;
            console.log(detail);
            var date = detail.created_at;
            var time = new Date(date * 1000);
            let y = time.getFullYear(); // 年
            let MM = time.getMonth() + 1; // 月
            let d = time.getDate(); // 日
            MM = MM < 10 ? "0" + MM : MM;
            d = d < 10 ? "0" + d : d;
            let timer = y + "-" + MM + "-" + d;
            console.log(timer);
            detail.created_at = timer;
            return;
          }
        });
    },
  },

  created() {
    this.id = this.$route.query.id;
    this.type = this.$route.query.type
    console.log('111', this.$route.query);
    this.getRow();
  },
};
</script>

<style>
.con {
  width: 1920px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.con video {
  width: 920px;
  border: 1px solid black;
}

.con p {
  width: 920px;
  word-wrap: break-word;
  font-size: 12px;
  color: #666;
}
.con img {
  width: 920px;
}
</style>
<style scoped>
/* scoped-样式只在本组件使用 */

.guide {
  font-size: 18px;
  font-weight: 500;
  color: #333;
  margin: 69px 0 64px 500px;
  padding-top: 105px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}

.time {
  font-size: 14px;
  font-weight: 500;
  color: #999;
  margin: 0 0 23px 500px;
}

.title {
  font-size: 34px;
  font-weight: 500;
  color: #333;
  margin: 0 0 52px 500px;
  width: 920px;
}
</style>