<template>
<!-- 平台介绍 -->
  <div>
        <img class="img" src="https://xititimalloss.oss-cn-hangzhou.aliyuncs.com/20230130/90c6176321ca92dcb5deb2bb82292b78.png" alt="">

    <div class="max">
      <!-- 喜提提 -->
      <div class="xtt">
        <div class="xtt_left">
          <div class="xtt_title">
            <div class="vertical"></div>
            <p class="xtt_p">喜提提</p>
          </div>
          <p class="xtt_content">
            喜提提是一个红包商城，响应国家政策创新消费场景的新购物体验平台，帮助消费者省钱，让消费者可以节省消费购物成本。消费即获得消费金额50%红包可兑换喜提提商城商品，实现一次消费，两次购物。帮助商家拓客引流，用打9折的成本做打5折的活动，为商家提升客流量，增加营业额，不仅能实现长久绑定本地精准客源，还可以得到喜提提线上私域流量加持；为扩大内需，促进消费内循环作出卓越贡献。
          </p>
        </div>
        <div class="xtt_right">
          <img
            src="https://xititimalloss.oss-cn-hangzhou.aliyuncs.com/20230222/ea5fbab4467c0350b6b787c22aa9f915.png"
            alt=""
          />
        </div>
      </div>
      <!-- 消费者 -->
      <div class="consumer">
        <img
          class="consumer_img"
          src="https://xititimalloss.oss-cn-hangzhou.aliyuncs.com/20230410/0158739ef6af7915efa038f48c1cec00.png"
          alt=""
        />
        <div class="consumer_right">
          <div class="c_r_top">
            <p>消费者</p>
            <img
              class="bridge"
              src="https://xititimalloss.oss-cn-hangzhou.aliyuncs.com/20230222/643aafa3b6d3d8e619d1b30c4c153f94.png"
              alt=""
            />
          </div>
          <p class="con_p">
            消费者在线下喜提提商户消费的同时还送50%红包，红包可在喜提提商城中兑换优质商品，品类众多，全都是大牌正品好货，放心兑换使用。线下商家通过线上平台实现线上线下消费一体化的全新购物模式，消费者在平台可以自由兑换商品，享受商品质量及服务。对于消费者来说，在提高生活质量的同时，还可以通过消费返红包的形式去商城免费兑换自己需要的产品，大大节省网购开支。在商城中还可以看到周边入驻商家，餐饮美食、休闲娱乐、酒店住宿等，消费者所消费返的红包都可以累积，在商城兑换心仪的产品。
          </p>
        </div>
      </div>
      <!-- 生活管家 -->
      <div class="introduce_three">
        <div class="introduce_three_top">
          <p class="introduce_xtt">生活管家</p>
          <img
            src="https://xititimalloss.oss-cn-hangzhou.aliyuncs.com/20230201/337cecdbb15b1501776a8623f1239c33.png"
            alt=""
            class="introduce_line weight"
          />
          <p class="introduce_three_p">
            喜提提的推广者称为“生活管家”，通过推广商家或者推荐生活管家获得收益。
          </p>
        </div>
        <div class="introduce_three_L_R">
          <div class="introduce_three_left">
            <div class="Equity">成为喜提提生活管家拥有四大权益:</div>
            <div class="number">
              <div class="num">1</div>
              <p>获得线下入驻商家1%经营流水（间接推荐0.5%）</p>
            </div>
            <div class="number">
              <div class="num">2</div>
              <p>直接享有云店带货佣金收益</p>
            </div>
            <div class="number">
              <div class="num">3</div>
              <p>直接享受推荐生活管家佣金</p>
            </div>
            <div class="number">
              <div class="num">4</div>
              <p>直接享受推荐生活管家佣金</p>
            </div>
          </div>
          <div class="introduce_three_right">
            <img src="@/assets/Elevator.png" alt="" class="Elevator" />
          </div>
        </div>
      </div>
      <!-- 商家入驻 -->
      <div class="introduce_fore">
        <div class="introduce_fore_top">
          <p class="introduce_xtt">商家入驻</p>
          <img
            src="https://xititimalloss.oss-cn-hangzhou.aliyuncs.com/20230201/337cecdbb15b1501776a8623f1239c33.png"
            alt=""
            class="introduce_line weight margin"
          />
          <p class="introduce_fore_p">
            商户入驻喜提提，可享受生活管家精准赋能扶持，全程陪伴在线为您答疑解惑
          </p>
          <p class="introduce_fore_p">通过双平台曝光引流，让更多人看到你的店</p>
          <p class="introduce_fore_p">通过灵活营销刺激消费，让更多人到店消费</p>
          <p class="introduce_fore_p">通过线上线下强联动，营销效果可追踪</p>
          <p class="introduce_fore_margin0">
            通过转化平台用户，导流线下商户转化私域流量
          </p>
          <img
            src="https://xititimalloss.oss-cn-hangzhou.aliyuncs.com/20230201/4aae1470b6425c7406a8458be00591ab.png"
            alt=""
            class="advantage"
          />
        </div>
        <div class="introduce_fore_body">
          <div class="introduce_fore_body_small">
            <img
              src="https://xititimalloss.oss-cn-hangzhou.aliyuncs.com/20230201/a2840528a9441b840aa2dbe290d88646.png"
              alt=""
              class="settle_logo"
            />
            <p class="settle_title">促入店</p>
            <p class="settle_p">平台流量线下精准导入专业扶持，赋能VI标识</p>
          </div>
          <div class="introduce_fore_body_small">
            <img
              src="https://xititimalloss.oss-cn-hangzhou.aliyuncs.com/20230201/bc1a47572a933cb08bc10637c5a466f8.png"
              alt=""
              class="settle_logo"
            />
            <p class="settle_title">促成交</p>
            <p class="settle_p">顾客买单，喜提提送消费金额的50%红包</p>
          </div>
          <div class="introduce_fore_body_small">
            <img
              src="https://xititimalloss.oss-cn-hangzhou.aliyuncs.com/20230201/159f84d67794c140a33e1326c43182bf.png"
              alt=""
              class="settle_logo"
            />
            <p class="settle_title">促客单</p>
            <p class="settle_p">满减优惠券，提升顾客单次消费客单价</p>
          </div>
          <div class="introduce_fore_body_small">
            <img
              src="https://xititimalloss.oss-cn-hangzhou.aliyuncs.com/20230201/8e199394d0c43cf3dbd8cd8586c9b731.png"
              alt=""
              class="settle_logo"
            />
            <p class="settle_title">促回头</p>
            <p class="settle_p">完全自主创新的新会员生态系统，牢牢粘住客户</p>
          </div>
          <div class="introduce_fore_body_small">
            <img
              src="https://xititimalloss.oss-cn-hangzhou.aliyuncs.com/20230201/91926275e128c0da0571d7ae32151a32.png"
              alt=""
              class="settle_logo"
            />
            <p class="settle_title">促循环</p>
            <p class="settle_p">异业联盟，形成系统内部交易闭环</p>
          </div>
          <div class="introduce_fore_body_small">
            <img
              src="https://xititimalloss.oss-cn-hangzhou.aliyuncs.com/20230201/b6f15d8221424a68cc9a8224720401da.png"
              alt=""
              class="settle_logo"
            />
            <p class="settle_title">促必胜</p>
            <p class="settle_p">同业优势突出，垄断半径百米客流，排它必胜</p>
          </div>
        </div>
      </div>
      <img
        src="https://xititimalloss.oss-cn-hangzhou.aliyuncs.com/20230130/7d02c3ae62724e4caf0ae36e5382a9a3.png"
        alt=""
        class="introduce_banner"
      />
    </div>
  </div>
</template>
<script>
export default {
  name: "introduce",
  data() {
    return {};
  },

  methods: {},

  created() {},
};
</script>
<style scoped>
/* scoped-样式只在本组件使用 */


.img {
  width: 1920px;
  padding-top: 105px;
} 

.max {
  display: flex;
  flex-direction: column;
  align-items: center;
}
/* 喜提提 */
.xtt {
  /* max-width: 100%; */
  padding: 90px 213px;
  box-sizing: border-box;
  /* background-color: pink; */
  display: flex;
  align-items: center;
}

.xtt_title {
  display: flex;
  align-items: center;
}

.vertical {
  width: 8px;
  height: 37px;
  background: #930e0e;
  border-radius: 4px;
  margin-right: 23px;
}
.xtt_p {
  font-size: 40px;
  color: #222;
  margin: 0;
}

.xtt_content {
  margin-top: 57px;
  width: 843px;
  height: 387px;
  font-size: 28px;
  font-weight: 500;
  color: #666666;
  line-height: 60px;
  text-indent: 2em;
}
.xtt_right img {
  width: 527px;
  height: 673px;
  margin-left: 124px;
}

/* 消费者 */
.consumer {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
.consumer_img {
  max-width: 1920px;
}

.consumer_right {
  position: absolute;
  top: 105px;
  right: 214px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.bridge {
  width: 111px;
  height: 8px;
  display: block;
}

.c_r_top p {
  font-size: 40px;
  color: #fff;
  font-weight: bold;
  margin: 0 0 30px 0;
}
.con_p {
  width: 721px;
  height: 402px;
  font-size: 24px;
  font-weight: 500;
  color: #ffffff;
  line-height: 54px;
  margin: 25px 0 0 0;
}

/* 生活管家 */
.introduce_line {
  margin-top: 30px;
  width: 119px;
  height: 8px;
  background: #930e0e;
  border-radius: 4px;
}


.introduce_xtt {
  font-size: 40px;
  font-weight: bold;
  color: #222;
  margin-top: 79px;
  margin-bottom: 0px;
}
.introduce_three_top {
  text-align: center;
}
.introduce_three_p {
  margin-top: 44px;
  margin-bottom: 74px;
  font-size: 30px;
  font-weight: 500;
  color: #666666;
}
.Equity {
  width: 540px;
  height: 68px;
  background: #bc1313;
  border-radius: 18px;
  font-size: 30px;
  font-weight: 500;
  color: #fff;
  text-align: center;
  line-height: 68px;
  margin-left: 89px;
  margin-bottom: 50px;
  margin-top: 39px;
}
.num {
  width: 40px;
  height: 40px;
  background: #bc1313;
  border-radius: 50%;
  font-size: 28px;
  color: #fff;
  text-align: center;
  line-height: 40px;
  margin-right: 23px;
}
.number {
  display: flex;
  align-items: center;
  margin-bottom: 50px;
}
.number p {
  font-size: 30px;
  color: #666;
  margin: 0;
}
.Elevator {
  width: 820px;
  height: 500px;
}
.introduce_three_L_R {
  display: flex;
  justify-content: center;
}

/* 生活管家 */
.introduce_fore_top {
  text-align: center;
  margin-top: 175px;
}
.margin {
  margin-bottom: 43px;
}
.introduce_fore_p {
  font-size: 30px;
  font-weight: 500;
  color: #666;
  margin-bottom: 45px;
  margin-top: 0;
}
.introduce_fore_margin0 {
  font-size: 30px;
  font-weight: 500;
  color: #666;
  margin-bottom: 0;
  margin-top: 0;
}
.advantage {
  margin-top: 61px;
  width: 354px;
  height: 35px;
  margin-bottom: 54px;
}
.introduce_fore_body_small {
  width: 220px;
  height: 270px;
  background: #ffffff;
  box-shadow: 0px 0px 40px 0px rgba(188, 19, 19, 0.15);
  border-radius: 10px;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.settle_logo {
  margin-top: 13px;
  width: 98px;
  height: 98px;
  margin-bottom: 13px;
}
.settle_title {
  font-size: 24px;
  font-weight: bold;
  color: #bc1313;
  margin: 0;
}
.settle_p {
  margin-top: 20px;
  font-size: 18px;
  font-weight: 500;
  color: #222222;
  line-height: 30px;
  margin-bottom: 0;
}
.introduce_fore_body {
  width: 1600px;
  display: flex;
  justify-content: space-between;
}
.introduce_banner {
  margin-top: 72px;
  max-width: 100%;
  display: block;
}
</style>