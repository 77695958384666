<template>
  <div>
    <div class="max">
      <img
        class="left_logo"
        src="https://xititimalloss.oss-cn-hangzhou.aliyuncs.com/20230405/0d211ad12c255aab07944b1d3c49746e.png"
        alt=""
      />
      <div class="information">
        <div class="infor_small">电话： 0775-83219759</div>
        <div class="infor_small">
          地址： 广东省深圳市福田区桥香路桥城东路2032号23楼
        </div>
        <div class="infor_small">邮编： 518000</div>
        <div class="infor_small">备案号： 粤ICP备18109045号-8</div>
      </div>
      <div class="code">
        <div class="code_small">
          <img
            src="https://xititimalloss.oss-cn-hangzhou.aliyuncs.com/20230405/c5e52f714cb90d6548c66f7d6135353b.png"
            alt=""
          />
          <div>扫一扫添加客服</div>
        </div>
        <div class="code_small">
          <img
            src="https://xititimalloss.oss-cn-hangzhou.aliyuncs.com/20230405/271b406b99d24feebe9ae52f1dad3edf.png"
            alt=""
          />
          <div>扫一扫关注小程序</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "",
  data() {
    return {};
  },
  components: {},
  watch: {},
  methods: {},
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
};
</script>
<style scoped>
/* scoped-样式只在本组件使用 */

.max {
  background-color: #bc1313;
  width: 1920px;
  height: 300px;
  display: flex;
}

.left_logo {
  width: 245px;
  height: 104px;
  margin: 57px 60px 0 250px;
}

.information {
  height: 190px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 68px 190px 0 0;
}

.infor_small {
  font-size: 21px;
  color: #fff;
  font-weight: 400;
  height: 25%;
}

.code {
  display: flex;
  width: 410px;
  justify-content: space-between;
  margin-top: 60px;
}

.code_small {
  width: 130px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.code_small img {
  width: 152px;
  height: 152px;
  margin-bottom: 22px;
}

.code_small div {
  font-size: 16px;
  color: #fff;
  font-weight: 500;
}
</style>