import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

// 引入路由组件
import home from '@/views/Home'
import join from '@/views/join'
import survey from '@/views/survey'
import introduce from '@/views/introduce'
import news from '@/views/news'
import contact from '@/views/contact'
import Merchant from '@/views/Merchant'
import steward from '@/views/steward'
import newsDetail from '@/views/newsDetail'

const routes = [
  {
    path: '/',
    redirect: '/home',
  },
  {
    path: '/home',
    name: 'home',
    component: home,
    meta: { show: true }
  },
  {
    path: '/join',
    name: 'join',
    component: join,
    meta: { show: true }
  },
  {
    path: '/survey',
    name: 'survey',
    component: survey,
    meta: { show: true }
  },
  {
    path: '/introduce',
    name: 'introduce',
    component: introduce,
    meta: { show: true }
  },
  {
    path: '/news',
    name: 'news',
    component: news,
    meta: { show: true }
  },
  {
    path: '/contact',
    name: 'contact',
    component: contact,
    meta: { show: true }
  },
  {
    path: '/Merchant',
    name: 'Merchant',
    component: Merchant,
    meta: { show: false }
  },
  {
    path: '/steward',
    name: 'steward',
    component: steward,
    meta: { show: false }
  },
  {
    path: '/newsDetail',
    name: 'newsDetail',
    component: newsDetail,
    meta: { show: true }
  },
]

const router = new VueRouter({
  routes
})

export default router
