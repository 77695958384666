<template>
  <!-- 商户入驻 -->
  <div>
    <div class="top_img">
     <img @click="showPopup" class="top_logo" src="https://xititimalloss.oss-cn-hangzhou.aliyuncs.com/20230411/b4f8d6eda2556b5e851744055e5b32fd.png" alt="">
    </div>
    
    <div></div>
    <div class="superiority1">
      <div class="superiority_logo">优势1：客户量翻倍</div>
      <div class="superiority1_flex">
        <div class="superiority_body">
          <div class="superiority1_small">
            <img
              class="superiority_img"
              src="https://xititimalloss.oss-cn-hangzhou.aliyuncs.com/20230331/91ceda3cd4c61d981d41bce9e696508f.png"
              alt=""
            />
            加入前
          </div>
          <div class="superiority1_small" style="margin-left: 27px">
            <img
              class="superiority_img"
              src="https://xititimalloss.oss-cn-hangzhou.aliyuncs.com/20230331/1b994fa73ee54c2bca93b7401eddf308.png"
              alt=""
            />
            加入后
          </div>
        </div>
        <img
          class="superiority1_case"
          src="https://xititimalloss.oss-cn-hangzhou.aliyuncs.com/20230331/9e51f0da6aa4cbbb3244760b723914d4.png"
          alt=""
        />
      </div>
    </div>
    <div class="superiority2">
      <div class="superiority_logo">优势2：无额外成本</div>
      <img
        class="superiority2_img"
        src="https://xititimalloss.oss-cn-hangzhou.aliyuncs.com/20230331/2247b3e0b94a8380bc4c54526b51d474.png"
        alt=""
      />
      <img
        class="superiority1_case"
        src="https://xititimalloss.oss-cn-hangzhou.aliyuncs.com/20230331/e21a167b2c29842af073ddba74faed0e.png"
        alt=""
      />
    </div>
    <div class="superiority3">
      <div class="superiority_logo">优势3：优质服务</div>
      <div class="superiority3_six">
        <div class="superiority3_small">
          <img
            src="https://xititimalloss.oss-cn-hangzhou.aliyuncs.com/20230331/580fa7ed1f1648a6a91d67999522f1f8.png"
            alt=""
          />
          <div class="superiority3_title">促入店</div>
          <div class="superiority3_Cop">
            平台流量线下精准导入专业扶持，赋能VI标识
          </div>
        </div>
        <div class="superiority3_small">
          <img
            src="https://xititimalloss.oss-cn-hangzhou.aliyuncs.com/20230405/664816fa1a37262bef2d6f24a5567f45.png"
            alt=""
          />
          <div class="superiority3_title">促成交</div>
          <div class="superiority3_Cop">
            顾客买单，喜提提送消费金额的50%红包
          </div>
        </div>
        <div class="superiority3_small">
          <img
            src="https://xititimalloss.oss-cn-hangzhou.aliyuncs.com/20230405/a85d4a1ce57315151aa7b9e1403acab4.png"
            alt=""
          />
          <div class="superiority3_title">促客单</div>
          <div class="superiority3_Cop">满减优惠券，提升顾客单次消费客单价</div>
        </div>

        <div class="superiority3_small">
          <img
            src="https://xititimalloss.oss-cn-hangzhou.aliyuncs.com/20230405/c4c3718cc261b54176d9eae6d62dd771.png"
            alt=""
          />
          <div class="superiority3_title">促回头</div>
          <div class="superiority3_Cop">
            完全自主创新的新会员生态系统，牢牢粘住客户
          </div>
        </div>
        <div class="superiority3_small">
          <img
            src="https://xititimalloss.oss-cn-hangzhou.aliyuncs.com/20230405/c7b1569efbc291695bf4eee5972e757e.png"
            alt=""
          />
          <div class="superiority3_title">促循环</div>
          <div class="superiority3_Cop">异业联盟，形成系统内部交易闭环</div>
        </div>
        <div class="superiority3_small">
          <img
            src="https://xititimalloss.oss-cn-hangzhou.aliyuncs.com/20230405/2689e63e443731d91c578019eb526316.png"
            alt=""
          />
          <div class="superiority3_title">促必胜</div>
          <div class="superiority3_Cop">
            同业优势突出，垄断半径百米客流，排它必胜
          </div>
        </div>
      </div>
    </div>
    <div class="superiority4">
      <div class="superiority4_logo">优势4：生态繁荣</div>
      <img
        src="https://xititimalloss.oss-cn-hangzhou.aliyuncs.com/20230331/55699ba19d0486e18feb9ffe280f26f8.png"
        alt=""
      />
    </div>
    <div class="flow_path">
      <img
        src="https://xititimalloss.oss-cn-hangzhou.aliyuncs.com/20230403/b41015b45eea4824a7700635a9700c11.png"
        alt=""
      />
    </div>
    <!-- 加入 -->
    <div class="footer">
      <div class="footer_title">喜提提期待您的加入</div>
      <img
        class="become"
        @click="showPopup"
        src="https://xititimalloss.oss-cn-hangzhou.aliyuncs.com/20230407/7c56db336081ced9e1a22a2f5ee5d820.png"
        alt=""
      />
    </div>
    <van-popup round v-model="show" :close-on-click-overlay="false">
      <div class="pop">
        <div class="title">
          <div class="bridge"></div>
          <div class="life">商家入驻</div>
        </div>
        <div class="body">
          <div class="one">1</div>
          <div class="one_con">
            <div class="one_con1">手机微信扫码喜提提小程序</div>
            <img
              src="https://xititimalloss.oss-cn-hangzhou.aliyuncs.com/20230411/0ccf172423c754c9aeeb4ce0760bf3d6.png"
              alt=""
            />
            <div class="one_con2">打开微信扫码进入喜提提</div>
          </div>
          <div class="drop">···········</div>
          <div class="two">2</div>
          <div class="two_con">
            <div class="one_con1">进入喜提提，点击“商家入驻”</div>
            <img
              class="steward"
              src="https://xititimalloss.oss-cn-hangzhou.aliyuncs.com/20230407/28c7be3a188bb2fba3ed9234f72ada8d.png"
              alt=""
            />
          </div>
        </div>
        <div class="fork" @click="close">×</div>
        <div class="point">点击这里开始入驻</div>
      </div>
    </van-popup>
  </div>
</template>
<script>
export default {
  name: "Merchant",
  data() {
    return {
      show: false,

    };
  },

  methods: {
     showPopup() {
      this.show = true;
    },
    close(){
      this.show = false
    }
  },

  created() {},
};
</script>
<style scoped>
/* scoped-样式只在本组件使用 */

.top_img {
  background: url(https://xititimalloss.oss-cn-hangzhou.aliyuncs.com/20230410/03545dddb5584a3163a88e76e4e67f40.png);
  width: 1920px;
  height: 814px;
  padding-top: 105px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  box-sizing: border-box;
  position: relative;
}

.top_logo {
  width: 235px;
  height: 81px;
  position: absolute;
  bottom: 139px;
  left: 199px;
  cursor: pointer;
}
.superiority_logo {
  background: url(https://xititimalloss.oss-cn-hangzhou.aliyuncs.com/20230331/63e0a545699ef9bb5eef5a398880baa3.png);
  background-size: cover;
  width: 284px;
  height: 42px;
  font-size: 26px;
  color: #fff;
  font-weight: 800;
  text-align: center;
  line-height: 42px;
  margin: 45px auto 36px;
}

.superiority_img {
  width: 340px;
  height: 250px;
  margin-bottom: 10px;
}

.superiority_body {
  display: flex;
  width: 1920px;
  justify-content: center;
}

.superiority1_small {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
  color: #333;
  font-weight: 500;
}
.superiority1_case {
  width: 707px;
  height: 138px;
  margin-top: 21px;
}

.superiority1_flex {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.superiority2 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.superiority2_img {
  width: 623px;
  height: 224px;
}

.superiority3_six {
  width: 706px;
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  justify-content: space-between;
}

.superiority3_small {
  display: flex;
  flex-direction: column;
}

.superiority3_small:nth-child(n + 4) {
  margin: 25px 0 37px;
}

.superiority3_small img {
  width: 228px;
  height: 180px;
}

.superiority3_title {
  font-size: 18px;
  color: #333;
  font-weight: 800;
  margin: 15px 0 14px;
}

.superiority3_Cop {
  width: 228px;
  font-size: 14px;
  font-weight: 500;
  color: #333;
  line-height: 30px;
}

.superiority4 {
  position: relative;
}

.superiority4_logo {
  background: url(https://xititimalloss.oss-cn-hangzhou.aliyuncs.com/20230331/63e0a545699ef9bb5eef5a398880baa3.png);
  background-size: cover;
  width: 284px;
  height: 42px;
  font-size: 26px;
  color: #fff;
  font-weight: 800;
  text-align: center;
  line-height: 42px;
  margin: 45px auto 36px;
  position: absolute;
  top: 60px;
  left: 818px;
}
.superiority4 img {
  width: 1920px;
}

/* 流程 */

.f_p_dig {
  display: flex;
  margin: 0 auto;
}

.f_p_small {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.num {
  width: 46px;
  height: 46px;
  background: #bc1313;
  border-radius: 50%;
  color: #fff;
  font-size: 26px;
  font-weight: 500;
  text-align: center;
  line-height: 46px;
}

.f_p_title {
  font-size: 18px;
  font-weight: 800;
  color: #333;
}

.f_p_Con {
  font-size: 12px;
  font-weight: 500;
  color: #333;
}

.flow_path img {
  width: 1920px;
  height: 628px;
}

/* 加入 */
.footer {
  width: 1920px;
  height: 212px;
  background-color: #bc1313;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.footer_title {
  font-size: 30px;
  color: #fff;
  font-weight: bold;
  margin-bottom: 15px;
}

.become {
  width: 127px;
  height: 53px;
  cursor: pointer;
}

/* 弹窗 */
.pop {
  width: 631px;
  height: 405px;
  padding: 68px 28px 50px;
  box-sizing: border-box;
  position: relative;
}

.title {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.bridge {
  width: 2px;
  height: 17px;
  background: #999999;
  margin-right: 11px;
}

.life {
  font-size: 17px;
  font-weight: bold;
  color: #333;
}

.body {
  width: 572px;
  height: 250px;
  background: #f7f8fa;
  border-radius: 8px;
  margin: 0 auto;
  display: flex;
}

.one {
  width: 28px;
  height: 28px;
  background: #ffffff;
  border: 2px solid #e3e3e3;
  border-radius: 50%;
  font-size: 15px;
  color: #333;
  text-align: center;
  line-height: 28px;
  font-weight: bold;
  margin: 27px 8px 0 17px;
}

.two {
  width: 28px;
  height: 28px;
  background: #ffffff;
  border: 2px solid #e3e3e3;
  border-radius: 50%;
  font-size: 15px;
  color: #333;
  text-align: center;
  line-height: 28px;
  font-weight: bold;
  margin: 27px 9px 0 0;
}

.one_con {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.one_con1 {
  font-size: 15px;
  color: #333;
  font-weight: bold;
  margin-top: 32px;
  margin-bottom: 22px;
}

.one_con img {
  width: 120px;
  height: 120px;
  margin-bottom: 22px;
}

.one_con2 {
  font-size: 15px;
  font-weight: bold;
  color: #999;
}

.drop {
  color: #999;
  font-size: 12px;
  margin: 38px 13px 0 15px;
}

.two_con img {
  width: 191px;
  height: 156px;
}

.fork {
  font-size: 30px;
  position: absolute;
  top: 25px;
  right: 30px;
  cursor: pointer;
}

.point {
  background: url(https://xititimalloss.oss-cn-hangzhou.aliyuncs.com/20230407/57f7b5783a4169452986b3b7dd7fbb6c.png);
  background-size: cover;
  background-repeat: no-repeat;
  width: 122px;
  height: 41px;
  text-align: center;
  line-height: 45px;
  color: #fff;
  font-size: 13px;
  font-weight: 500;
  position: absolute;
  top: 257px;
  right: 134px;
}
</style>